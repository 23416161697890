import { Component } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import { AppConstants } from './app.constants';
import {OnInit} from "@angular/core";
import {AwsUtil} from "./service/aws.service";
import {UserLoginService} from "./service/user-login.service";
import {CognitoUtil, LoggedInCallback} from "./service/cognito.service";
import {NetlockService} from "./service/netlock.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, LoggedInCallback {

  

  private mostrarMenu = false;
  
  constructor(public router: Router, public netLock: NetlockService,  public awsUtil: AwsUtil, public userService: UserLoginService, public cognito: CognitoUtil,private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    for (const icon of AppConstants.xhr.iconos) {
      this.matIconRegistry.addSvgIcon(
        icon,
        domSanitizer.bypassSecurityTrustResourceUrl(`./assets/iconos/${icon}.svg`)
      );
    }

    }
  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.userService.isAuthenticated(this);
   

  }

 
  isLoggedIn(message: string, isLoggedIn: boolean) {
      console.log("AppComponent: the user is authenticated: " + isLoggedIn);
      
      if (isLoggedIn)
      {
        if (Object.keys(this.netLock.userInfo.misCandados).length == 0)
          this.router.navigate(['/cargando']);
      }
      let mythis = this;
      this.cognito.getIdToken({
          callback() {

          },
          callbackWithParam(token: any) {
              // Include the passed-in callback here as well so that it's executed downstream
              console.log("AppComponent: calling initAwsService in callback")
              mythis.awsUtil.initAwsService(null, isLoggedIn, token);
              
              

          }
      });

      

  }

  setMostrarMenu(value)
  {
    this.mostrarMenu = value;
  }


}
