import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { from } from 'rxjs';
import { AppConstants } from './app.constants';

@Injectable()
export class AppService {
  private config: any;
  constructor(private http: HttpClient) { };

  init() {
    const configUrl = '~/../assets/config.json';
        return new Promise((resolve) => {
            this.http.get(configUrl)
            .toPromise()
            .then((config: any) => {
                this.config = config;
                console.log('Configurationd fetched  : ', this.config);
                AppConstants.xhr.iconos = this.config.iconos;
                resolve(true);
            })
            .catch( (err) => console.log(err));
        });
  }
}
