import { Component, OnInit, Inject, Input } from '@angular/core';
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {NetlockService} from "../../service/netlock.service";
import { MatSnackBar } from "@angular/material";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']

})



export class HistorialComponent implements OnInit {
  @Input() ride = {
    id: 12,
    startTime: 0,
    distanciaTotal: 0,
    velocidadMedia: 0,
    borrado: false

  };
  
  lat = 51.678418;
  lng = 7.809007;
  selectFirst = true;
  public pids = [
    {
      latitud: 0,
      longitud: 0
    }
  ];
  showSpinner = false;
  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar,   public netLock: NetlockService, public router: Router, public userService: UserLoginService) {
    this.userService.isAuthenticated(this);

  
    
              
   }

  
   

   verride(ride)
   {
     this.ride = ride;
     let pids = JSON.parse(ride.pids);
     if (pids)
      this.pids = pids
     else
      {
        this._snackBar.open("No hay Pids!", '', {duration: 20});
        this.pids = Array();
      }
      
    }

    

    
    
  ngOnInit() {
    
    this.netLock.getRides().subscribe(
      (rides) => {
        for (let r of rides)
        {
          this.selectFirst = true;
            this.ride = r;
            this.verride(r);
            break;
        }
      }
    );
    this.netLock.ridesUpdated.subscribe(
      (rides) => {
        for (let r of rides)
        {
          this.selectFirst = false;
            this.ride = r;
            this.verride(r);
            break;
        }
      }
    );
  }

  

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
        this.router.navigate(['/login']);
    }
}


}

