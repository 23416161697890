import { Injectable, EventEmitter} from '@angular/core';
import { UserParametersService } from './user-parameters.service';
import { Callback, CognitoUtil, LoggedInCallback} from './cognito.service';
import { UserLoginService } from './user-login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError, count } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { HistorialComponent } from '../components/historial/historial.component';
import { Router } from "@angular/router";
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})

export class mesh {
  public position: []
}



export class UserInfo {
    public accessToken: string;
    public cognitoUser;
    public netlockUserInfo = {
      id: 0,
      candados: Array(),
      givenName: '',
      familyName: '',
      birthdate: '',
      foto: '',
      user: ''
    };
    public misCandados = Array();
    public defaultCandado = {
      mac : "",
      id: 0,
     };
    public totales = {};
    public rides = [];
    public ridesPage = 1;
    public lastPoint = {
      longitud: 0,
      latitud: 0,
      fecha: 0
    };
    public defaultRide = {
      id: 0,
      startTime: 0,
      distanciaTotal: 0,
      velocidadMedia: 0,
      borrado: false
  
    }

}

export class NetlockService implements LoggedInCallback {

  public ridesUpdated:EventEmitter<any> = new EventEmitter();

  apiURL = 'https://apiv2.luma.es';

  public jwtToken: String;
  public userInfo: UserInfo = new UserInfo();

  
  httpOptions = { };  
 
  constructor(public router: Router, private http: HttpClient, public userParams: UserParametersService, public userService: UserLoginService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);    
  }

  init()
  {
     this.cognitoUtil.getAccessToken(new AccessTokenCallback(this));
    
     this.httpOptions = { headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + this.userInfo.accessToken
    })}

    }

   isLoggedIn(message: string, isLoggedIn: boolean) {
    }

   getLastLocation() {
    return this.http.get(this.apiURL + '/mesh_beacons.json?mac='+this.getDefaultMajorMinor(), this.httpOptions)
   }

   
   getApiRides(page = 0) {
     
    if (page<=0)
      page = this.userInfo.ridesPage;

    return this.http.get(this.apiURL + '/recorridos_usuarios.json?user='+this.userInfo.netlockUserInfo.id+'&borrado=0&page='+page, this.httpOptions)
    }

  borrarRide(ride){
    return this.http.put(this.apiURL + '/recorridos_usuarios/'+ride.id, '{"borrado":true}' , this.httpOptions)
  }
  
   getApiTotales() { 

    return this.http.get(this.apiURL + '/get/totales/byUser/'+this.userInfo.netlockUserInfo.id, this.httpOptions)
    }

  

  
   getDefaultCandado(){}
   getDefaultMac(){}

   getDefaultMajorMinor(){

      return this.userInfo.defaultCandado.id.toString(16).padStart(6, "0").toUpperCase();

   }

   getCandados(){}
   getLastRide(){}

   getTotalesGenerales(){
  

    let totales = {
      recorridos: 0,
      distancia: 0,
      velocidad: 0

    }
    for(let total of this.userInfo.totales[1])
    {

      totales.recorridos = totales.recorridos + Number(total.total);
      totales.distancia = totales.distancia + Number(total.totalDistancia);
      totales.velocidad = totales.velocidad + Number(total.totalVelocidadMedia);
    }
   
    totales.velocidad = (totales.velocidad/totales.recorridos);

    return totales;

  }
  
   setLastLocation(){
    this.getLastLocation().subscribe((meshBeacons: any) => {    
      let lastTime = 0;
      let lastPostionTemp = { Location: '[]' }
      for (let position  of meshBeacons)
      {
        if (position.timestamp > lastTime)
          {
            lastPostionTemp = position;
            lastTime = position.timestamp;
          }
      }

      this.userInfo.lastPoint = JSON.parse(lastPostionTemp.Location);

  });
  }

   getApiNetlockUserInfo()
   {
    return this.http.get(this.apiURL + '/users_infos.json?user='+this.userInfo.cognitoUser.getUsername().replace("AT","@"), this.httpOptions)
   }

   getApiCandado(candado)
   {
     return this.http.get(this.apiURL + candado + ".json", this.httpOptions);
   }

   setDefaultCandado(candado)
   {
      candado.default = 1;
      this.userInfo.defaultCandado = candado;
      this.setTotalesByCandado();
      this.userInfo.ridesPage = 1;

      this.getApiRides().subscribe((rides: []) => {
        this.userInfo.rides = rides;
        for (let r of rides)
        {
            this.setDefaultRide(r);
            break;
        }
        this.ridesUpdated.emit(this.userInfo.rides);

      });
      
      this.setLastLocation();
   }

   setTotalesByCandado()
   {
    
        
        for(let total of this.userInfo.totales[1])
        {
          if (total.mac == this.userInfo.defaultCandado.mac)
            {
              this.userInfo.totales[0] = [total]
            }
        }
        
      
     

   }
   setDefaultRide(ride)
   {
     this.setDefaultRide = ride;
   }

   getAllCandados()
   {

   }
    
   getRides() {

      return of(this.userInfo.rides);
  }

 
   jsonCustomDecode(jsonText)
   {
     let coded = JSON.parse(jsonText);
     return coded;
   }

getApiFromUri(){}


    // Error handling 
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

}



export class AccessTokenCallback implements Callback {
  constructor(public netLock: NetlockService) {
  }

  callback() {
  }

  callbackWithParam(result) {
      this.netLock.userInfo.cognitoUser = this.netLock.cognitoUtil.getCurrentUser();

      this.netLock.userInfo.accessToken = result;
  }
}


