import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../../../service/user-login.service";
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../../../../service/cognito.service";
import { NetlockService } from 'src/app/service/netlock.service';


export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {
    email: string;
    password: string;
    errorMessage: string;
    mfaStep = false;
    showSpinner = false;
    NewUserPassword = new NewPasswordUser();
    mfaData = {
        destination: '',
        callback: null
    };

    constructor(public router: Router,
                public userService: UserLoginService,
                public netlockService: NetlockService) {
        console.log("LoginComponent constructor");
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated(this);
    }

    onLogin() {

        this.showSpinner = true;
        if (this.email == null || this.password == null) {
            this.showSpinner = false;
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error

            this.showSpinner = false;
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log("redirecting");
                this.router.navigate(['/confirmRegistration', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                console.log("redirecting to set new password");

                this.NewUserPassword.existingPassword = this.password;
                this.NewUserPassword.username = this.email;

                this.router.navigate(['/newPassword', this.NewUserPassword]); 
            }
        } else { //success
            this.router.navigate(['/cargando']);
        }
    }

    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
        this.mfaData.callback = (code: string) => {
            if (code == null || code.length === 0) {
                this.errorMessage = "Code is required";
                return;
            }
            this.errorMessage = null;
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/cargando']);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false;   //necessary to prevent href navigation
    }
}
