export const environment = {
  production: false,

  region: 'eu-west-1',

  identityPoolId: 'eu-west-1:cdded402-f811-4931-9f1c-ebd02293a37d',
  userPoolId: 'eu-west-1_oQXoZf7NA',
  clientId: '26ddnmcik4ahehm1abclv4n7vi',

  rekognitionBucket: 'rekognition-pics',
  albumName: "usercontent",
  bucketRegion: 'us-east-1',

  ddbTableName: 'LoginTrail',

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: ''

};
