import { Component, OnInit } from '@angular/core';
import {UserLoginService} from "../../service/user-login.service";
import {Router} from "@angular/router";
import {LoggedInCallback} from "../../service/cognito.service";
import {NetlockService} from "../../service/netlock.service";


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})

export class PerfilComponent implements OnInit, LoggedInCallback {

  public datosForm = {
    nombre: this.netLock.userInfo.netlockUserInfo.givenName,
    apellido: this.netLock.userInfo.netlockUserInfo.familyName,
    fechaNacimiento: this.netLock.userInfo.netlockUserInfo.birthdate
  }

  constructor(public router: Router, public userService: UserLoginService, public netLock: NetlockService) {
    this.userService.isAuthenticated(this);
   
    console.log(this.netLock.userInfo);
}

ngOnInit() {

}

isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
        this.router.navigate(['/login']);
    }
}

}
