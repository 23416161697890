import { Component, OnInit } from '@angular/core';
import {NetlockService} from "../../service/netlock.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})

export class HeaderComponent implements OnInit {
    
  public misCandados = {};
  constructor(public netLock: NetlockService) { 
    this.misCandados = netLock.userInfo.misCandados;
    }

  ngOnInit() {

  }

}
