import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {UserLoginService} from "../../service/user-login.service";
import {LoggedInCallback} from "../../service/cognito.service";
import {Router} from "@angular/router";
import {NetlockService} from "../../service/netlock.service";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent  implements OnInit {

  @Output() rideActual = new EventEmitter<string>();

  public misCandados = {};
  
  ride = {
    id: 0,
    startTime: 0,
    distanciaTotal: 0,
    velocidadMedia: 0,
    borrado: false

  };
  showSpinner = false;
  
    public paginaActiva = this.router.url;

    constructor(public dialog: MatDialog, public netLock: NetlockService, public router:Router) {
      this.paginaActiva = this.router.url;
      console.log(this.paginaActiva)
    }
    
    setActive(paginaActiva)
    {
      this.paginaActiva = paginaActiva;
    
      this.router.navigate(['/netlock/'+paginaActiva]);
    }
    enviarMensaje(ride){
      this.ride = ride;

      this.rideActual.emit(ride);
    }

    ngOnInit() {      
      this.misCandados = this.netLock.userInfo.misCandados;
      
    }

  
    delete(ride)
    {
      const dialogRef = this.dialog.open(DialogBorrar, {
        width: '250px',
        data: {ride: ride}
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          
          this.netLock.borrarRide(ride).subscribe((result: []) => {
            console.log(result)
            ride.borrado = true;
          });

        }
      });
  
    }

    setDefaultRide(ride)
    {
      this.netLock.setDefaultRide(ride);
    }

    handler(event)
    {
      
      let maxPos = event;
 
      if ( maxPos % 5 == 0)
        {
          if (maxPos >= this.netLock.userInfo.ridesPage*5)
             {
               this.netLock.userInfo.ridesPage++;
 
               this.showSpinner = true;
               this.netLock.getApiRides().subscribe((rides: []) => {
 
                 for (let r of rides)
                 {
                   this.netLock.userInfo.rides.push(r);
                 }
                 this.showSpinner = false;
               });
               
             }
               
        } 
 
    }
 
}


@Component({
  selector: 'dialog-borrar',
  templateUrl: 'dialog-borrar.html',
})
export class DialogBorrar {

  constructor(
    public dialogRef: MatDialogRef<DialogBorrar>) {}

    
  onNoClick(): void {
    this.dialogRef.close();
  }

}