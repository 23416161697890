import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { APP_INITIALIZER, APP_BOOTSTRAP_LISTENER, ComponentRef } from '@angular/core';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { HttpClientModule } from "@angular/common/http";
import { AppService } from './app.service';
import { MenuLateralComponent } from './components/menu-lateral/menu-lateral.component';
import { AgmCoreModule } from '@agm/core';
import {HttpModule} from "@angular/http";

import { routing } from './app.routes';


import { HeaderComponent } from './components/header/header.component';
import { LocalizarComponent } from './components/localizar/localizar.component';
import { HistorialComponent } from './components/historial/historial.component';
import { PerfilComponent } from './components/perfil/perfil.component';


//Aws
import {FormsModule} from "@angular/forms";
import {UserRegistrationService} from "./service/user-registration.service";
import {UserParametersService} from "./service/user-parameters.service";
import {UserLoginService} from "./service/user-login.service";
import {CognitoUtil} from "./service/cognito.service";
import {AwsUtil} from "./service/aws.service";
import {LoginComponent} from "./components/public/auth/login/login.component";
import {RegisterComponent} from "./components/public/auth/register/registration.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./components/public/auth/forgot/forgotPassword.component";
import {LogoutComponent, RegistrationConfirmationComponent} from "./components/public/auth/confirm/confirmRegistration.component";
import {ResendCodeComponent} from "./components/public/auth/resend/resendCode.component";
import {NewPasswordComponent} from "./components/public/auth/newpassword/newpassword.component";
import { MFAComponent } from './components/public/auth/mfa/mfa.component';
import { NetlockService } from './service/netlock.service';
import { CargandoComponent } from './components/cargando/cargando.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DialogBorrar } from './components/menu-lateral/menu-lateral.component';
import { IsLoadingModule } from '@service-work/is-loading'
@NgModule({
  declarations: [
    AppComponent,
    MenuLateralComponent,
    HeaderComponent,
    LocalizarComponent,
    HistorialComponent,
    PerfilComponent,
    NewPasswordComponent,
    LoginComponent,
    LogoutComponent,
    RegistrationConfirmationComponent,
    ResendCodeComponent,
    ForgotPasswordStep1Component,
    ForgotPassword2Component,
    RegisterComponent,
    MFAComponent,
    AppComponent,
    CargandoComponent,
    DialogBorrar
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    routing,
    HttpModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD6JHL7C1mBTQyDmJ3t1mTfs_uhojO3dnU'
    }),
    ScrollingModule,
    IsLoadingModule
  ],
  providers:    [
    CognitoUtil,
    AwsUtil,
    UserRegistrationService,
    UserLoginService,
    UserParametersService,
    AppService,
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: () => {
        return (component: ComponentRef<any>) => {
          console.log('[APP_BOOTSTRAP_LISTENER] bootstrapped component:', component.instance);
        }
      },
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppService,
      deps: [AppService],
      multi: true
    },
    NetlockService
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogBorrar]
})
export class AppModule { }

export function initAppService(appService: AppService) {
  return () => appService.init();
}
