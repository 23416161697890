import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {NetlockService} from "../../service/netlock.service";
import { Observable, throwError, of } from 'rxjs';

@Component({
  selector: 'app-localizar',
  templateUrl: './localizar.component.html',
  styleUrls: ['./localizar.component.scss']
})
export class LocalizarComponent implements OnInit {
  lat = 51.678418;
  lng = 7.809007;
  public lastPosition = {};
  public lastPoint = {
    longitud: 0,
    latitud: 0,
    fecha: 0
  };
  constructor(public router: Router, public userService: UserLoginService, public netLock: NetlockService) {
    this.userService.isAuthenticated(this);
   }

  

  ngOnInit() {

  }
  
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
        this.router.navigate(['/login']);
    }
  }


}
