import { Routes, RouterModule, Router } from '@angular/router';
import {ModuleWithProviders} from "@angular/core";

import { HistorialComponent } from './components/historial/historial.component';
import { LocalizarComponent } from './components/localizar/localizar.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import {LoginComponent} from "./components/public/auth/login/login.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./components/public/auth/forgot/forgotPassword.component";
import {LogoutComponent, RegistrationConfirmationComponent} from "./components/public/auth/confirm/confirmRegistration.component";
import {ResendCodeComponent} from "./components/public/auth/resend/resendCode.component";
import {NewPasswordComponent} from "./components/public/auth/newpassword/newpassword.component";
import { CargandoComponent } from './components/cargando/cargando.component';



const routesDh: Routes = [
  { path: '', redirectTo: '/login',  pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: {animation: 'FilterPage'}},
  { path: 'resendCode', component: ResendCodeComponent},
  { path: 'cargando', component: CargandoComponent},
  { path: 'forgotPassword/:email', component: ForgotPassword2Component},
  { path: 'forgotPassword', component: ForgotPasswordStep1Component},
  { path: 'newPassword', component: NewPasswordComponent},
  { path: 'netlock/perfil', component: PerfilComponent },
  { path: 'netlock/localizar', component: LocalizarComponent},
  { path: 'netlock/historial', component: HistorialComponent},
  { path: 'netlock/logout', component: LogoutComponent}
];





export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(routesDh);

