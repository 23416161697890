import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NetlockService } from "../../service/netlock.service";
import { forkJoin } from 'rxjs';  

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss']
})
export class CargandoComponent implements OnInit {
 

  constructor( public router: Router, public NetlockService: NetlockService) { 

 

  }

  ngOnInit() {
        this.NetlockService.init();
        
        this.NetlockService.getApiNetlockUserInfo().subscribe((netlockUserInfo: {}) => {
          
          this.NetlockService.userInfo.netlockUserInfo = netlockUserInfo[0];
          let arrayCandados = [];
        
          for (let candado of  this.NetlockService.userInfo.netlockUserInfo.candados)
          {
            arrayCandados.push( this.NetlockService.getApiCandado(candado));
          }

          forkJoin(arrayCandados).subscribe(response => {
            
            
            this.NetlockService.userInfo.misCandados = response;
            for (var i =  this.NetlockService.userInfo.misCandados.length - 1; i >= 0; i--) {
              
              if ( this.NetlockService.userInfo.misCandados[i].borrado) {
                this.NetlockService.userInfo.misCandados.splice(i, 1);
              }
          }
            
          this.NetlockService.getApiTotales().subscribe((totales: {}) => {

            
            this.NetlockService.userInfo.totales = totales;



            this.NetlockService.setDefaultCandado( this.NetlockService.userInfo.misCandados[1]);
              this.router.navigate(['/netlock/historial']);

            });
            
            
            


          }, error => {
            console.error(error);
          });

        });
      }
  
  isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/login']);
        }
    }
}
